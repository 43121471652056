import * as React from "react"
import { graphql } from "gatsby"
// import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import HomeTile from "../components/HomeTile"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet'
// import Slider from "react-slick"
// import { instagramCarouselSettings } from "../scripts/settings"
// import { limitTextLength } from "../scripts/helpers"


const IndexPage = ({ data }) => {
  const prismicData = data.prismicHome.data
  // const instagramData = data.allInstaNode.nodes

  const introText = prismicData.home_intro_text.html
  const bannerLogo = getImage(prismicData.home_logo.gatsbyImageData)

  const actorsTileImage = getImage(prismicData.actors_tile_image.gatsbyImageData)
  const commercialTalentTileImage = getImage(prismicData.commercial_talent_tile_image.gatsbyImageData)
  const modelsTileImage = getImage(prismicData.models_tile_image.gatsbyImageData)

  // const socialMediaTitle = prismicData.social_media_title.text

  return (
    <Layout homePage={true}>
      <Helmet>
        <title>Monarch People Home Page</title>
        <meta name='description' content='Home page for talent management agency Monarch People' />
      </Helmet>

      <title>Home Page</title>

      <div className="home-page-content">

        <div className="home-banner-container">
          <GatsbyImage image={bannerLogo} width="900px" height="611px" alt="Monarch People logo" 
              className="banner-image-container home-page" imgClassName="banner-image" objectFit="contain"/>
        </div>

        <div className="intro-text-container">
          <div className="intro-text">
            {parse(introText)}
          </div>
        </div>

        <div className="home-tiles-container">
          <div className="home-tiles">
            <HomeTile address="/actors" title="Actors" image={actorsTileImage} />
            <HomeTile address="/talent" title="Commercial Talent" image={commercialTalentTileImage} />
            <HomeTile address="/models" title="Models" image={modelsTileImage} />
          </div>
        </div>

        {/* Instagram Feed */}
        {/* ** Replace placeholder alt text */}
        {/* <div className="social-media-container">
          <h2 className="social-media-heading">{socialMediaTitle}</h2>

          <div className="instagram-carousel">
            <Slider {...instagramCarouselSettings}> 
                  {instagramData.map((post) => {
                    return (
                      <Link to={post.id ? ("https://www.instagram.com/p/" + post.id) : "/"} className="instagram-post" target="_blank" rel="noreferrer">
                        <GatsbyImage image={getImage(post.localFile)} alt="Instagram photograph" />
                        <p>{limitTextLength(post.caption, 340)}</p>
                      </Link>
                    )
                  })}
            </Slider>
          </div>
        </div> */}

      </div>
      
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
//   query {
//     prismicHome {
//       data {
//         actors_tile_image {
//               gatsbyImageData
//         }
//         models_tile_image {
//               gatsbyImageData
//         }
//         home_logo {
//               gatsbyImageData
//         }
//         commercial_talent_tile_image {
//               gatsbyImageData
//         }
//         home_intro_text {
//           html
//         }
//         social_media_title {
//           text
//         }
//       }
//     }
//     allInstaNode (
//       sort: {
//         order: [DESC],
//         fields: [timestamp]
//       } )
//      {
//       nodes {
//         id
//         caption
//         timestamp
//         localFile {
//           childImageSharp {
//             gatsbyImageData(width: 400)
//           }
//         }
//       }
//     }
//   }
// `


//Without instagram
export const query = graphql`
  query {
    prismicHome {
      data {
        actors_tile_image {
          gatsbyImageData(width: 360)
        }
        models_tile_image {
          gatsbyImageData(width: 360)
        }
        home_logo {
          gatsbyImageData(width: 900)
        }
        commercial_talent_tile_image {
          gatsbyImageData(width: 360)
        }
        home_intro_text {
          html
        }
        social_media_title {
          text
        }
      }
    }
  }
`

