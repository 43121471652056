import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function HomeTile(props) {
  

  return (
    <Link to={props.address ?? "/"} className="home-tile">
      <div className="home-tile-content">
        <GatsbyImage image={props.image} alt={props.title} className="home-tile-image-container" imgClassName="home-tile-image" objectFit="cover" />

          <h3 className="home-tile-text">{props.title}</h3>
        </div>
    </Link>
  )
  
}
